import * as React from 'react';
import { defaultContext } from '../../__config__/context';

import { routeConfig, RouteConfig } from '../../__config__/routes';
import { DigtectiveContextProvider } from '../../__lib__/digtective/context/DigtectiveContextProvider';

interface ISiteContext {
  header: any;
  routeConfig: RouteConfig | null;
  contextData: any;
  setContextData: any;
}

export const SiteContext = React.createContext<ISiteContext>({
  header: null,
  routeConfig: null,
  contextData: null,
  setContextData: () => {}
});

export const SiteContextProvider: React.FC = ({ children }) => {
  const [contextData, setContextData] = React.useState({});

  return (
    <SiteContext.Provider
      value={{
        routeConfig,
        contextData,
        setContextData,
        ...defaultContext()
      }}
    >
      <DigtectiveContextProvider>{children}</DigtectiveContextProvider>
    </SiteContext.Provider>
  );
};
