import { Digtective } from '..';
import { DigtectiveSubmitPayload } from '../types';

export function updateGoogleClickId(
  facade: Digtective,
  googleClickId?: DigtectiveSubmitPayload['googleClickId']
) {
  if (!googleClickId) return;
  const session = facade.getSession();
  if (
    session?.googleClickId &&
    session?.googleClickId?.type !== googleClickId.type
  ) {
    return facade.updateSession({ googleClickId });
  }
  return facade.updateSession({ googleClickId });
}
