import { Digtective } from '..';

export function getSession(facade: Digtective) {
  try {
    const { storage, options } = facade ?? {};
    const { sessionName } = options ?? {};
    const session = storage?.getItem(sessionName);
    if (!session) return null;
    return JSON.parse(session);
  } catch (err) {
    console.error('getSession error', err);
    return null;
  }
}
