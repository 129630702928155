import { Digger } from '..';

export type DiggerType = { digger: ReturnType<typeof Digger> };

export enum DiggerFieldType {
  Zipcode = '6'
}

export interface SubmitInput {
  zip?: string;
}
export interface SubmitCallbackArguments {
  diggerId?: string;
}
export type SubmitCallback = (args: SubmitCallbackArguments) => void;
export type CommonHookProps = DiggerType;
