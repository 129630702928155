import { SubmitCallback, SubmitInput } from './context/types';
import {
  DigtectiveOptions,
  DigtectiveRequestInput,
  DigtectiveSubmitPayload
} from './types';

import { createSession } from './utils/createSession';
import { getPayload } from './utils/getPayload';
import { getSession } from './utils/getSession';
import { getToken } from './utils/getToken';
import { request } from './utils/request';
import { getSessionExpiry } from './utils/getSessionExpiry';
import { submitWithDigger } from './utils/submitWithDigger';
import { updateAdCode } from './utils/updateAdCode';
import { updateFacebookClickId } from './utils/updateFacebookClickId';
import { updateGoogleClickId } from './utils/updateGoogleClickId';
import { updateKeyword } from './utils/updateKeyword';
import { updateSession } from './utils/updateSession';
import { isSessionExpired } from './utils/isSessionExpired';
import { removeExpiredSession } from './utils/removeExpiredSession';
import { getCustomFields } from './utils/getCustomFields';
import { validatePayload } from './utils/validatePayload';

export class Digtective {
  options: DigtectiveOptions;
  storage: typeof localStorage | typeof sessionStorage | null;
  constructor(options: DigtectiveOptions) {
    if (!options?.baseURL)
      throw new Error('Digtective is missing baseURL. Add it to env');
    if (!options?.token)
      throw new Error('Digtective is missing api key. Add it to env');
    if (!options?.sessionName)
      throw new Error('Digtective is missing sessionName. Add it to env');
    this.options = options;
    this.storage = localStorage;
  }

  createSession() {
    return createSession(this);
  }

  getSessionExpiry() {
    return getSessionExpiry();
  }

  isSessionExpired(session: unknown) {
    return isSessionExpired(this, session);
  }

  getToken() {
    return getToken(this);
  }

  getSession() {
    return getSession(this);
  }

  getCustomFields(input: SubmitInput) {
    return getCustomFields(this, input);
  }

  validatePayload(input: DigtectiveSubmitPayload) {
    return validatePayload(input);
  }

  getPayload(input: SubmitInput) {
    return getPayload(this, input);
  }

  updateSession(input: unknown) {
    return updateSession(this, input);
  }

  updateKeyword(keyword?: string) {
    return updateKeyword(this, keyword);
  }

  updateAdCode(adCode: DigtectiveSubmitPayload['adCode']) {
    return updateAdCode(this, adCode);
  }

  updateFacebookClickId(
    facebookClickId: DigtectiveSubmitPayload['facebookClickId']
  ) {
    return updateFacebookClickId(this, facebookClickId);
  }

  updateGoogleClickId(googleClickId: DigtectiveSubmitPayload['googleClickId']) {
    return updateGoogleClickId(this, googleClickId);
  }

  removeExpiredSession(session: unknown) {
    return removeExpiredSession(this, session);
  }

  async request<T>(path: string, input: DigtectiveRequestInput): Promise<T> {
    return request(this, path, input);
  }

  async submitWithDigger(input: SubmitInput, callback: SubmitCallback) {
    return submitWithDigger(this, input, callback);
  }
}

export const Digger = (options: DigtectiveOptions) => {
  if (typeof window === 'undefined') return null;
  return new Digtective(options);
};
