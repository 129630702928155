import { useMemo } from 'react';
import { Digger } from '../..';
import { ENV_CONFIG } from '../../../../__config__/env';

export function useInstance() {
  return useMemo(() => {
    return Digger({
      token: ENV_CONFIG.DIGGER_API_KEY_V2,
      baseURL: ENV_CONFIG.DIGGER_BASE_URL,
      sessionName: 'digtective'
    });
  }, []);
}
