import { Digtective } from '..';
import { DigtectiveRequestInput } from '../types';

export async function request(
  facade: Digtective,
  path: string,
  input: DigtectiveRequestInput
) {
  const { baseURL } = facade?.options ?? {};
  const { method = 'POST', body } = input ?? {};
  const url = new URL(`${baseURL}${!path.startsWith('/') ? `/${path}` : path}`);
  const request = await fetch(url, {
    method,
    headers: {
      Authorization: facade.getToken(),
      'Content-Type': 'application/json'
    },
    body
  });
  const response = await request.json();
  return response;
}
