import { Digtective } from '..';
import { DigtectiveSubmitPayload } from '../types';

export function updateFacebookClickId(
  facade: Digtective,
  facebookClickId?: DigtectiveSubmitPayload['facebookClickId']
) {
  if (!facebookClickId) return;
  const session = facade.getSession();
  if (
    session?.facebookClickId &&
    session.facebookClickId?.type !== facebookClickId?.type
  ) {
    return facade.updateSession({ facebookClickId });
  }
  return facade.updateSession({ facebookClickId });
}
