import React, { useState } from 'react';
import { useInstance } from './hooks/useInstance';
import { useSession } from './hooks/useSession';
import { DigtectiveContext } from './DigtectiveContext';
import { useUrlParams } from './hooks/useUrlParams';

interface DigtectiveContextProviderProps {
  children: React.ReactNode;
}

export function DigtectiveContextProvider({
  children
}: DigtectiveContextProviderProps) {
  const digger = useInstance();
  const [ready, setReadySession] = useState(false);
  useSession({ digger }, () => setReadySession(true));
  useUrlParams({ digger, ready });
  return (
    <DigtectiveContext.Provider
      value={{
        submitWithDigger: (input, callback) =>
          digger?.submitWithDigger(input, callback)
      }}
    >
      {children}
    </DigtectiveContext.Provider>
  );
}
