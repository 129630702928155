import { useEffect } from 'react';
import { CommonHookProps } from '../types';
import { getUrlSearchParams } from '../utils/getUrlParam';

interface UseUrlParamsProps extends CommonHookProps {
  ready: boolean;
}

export function useUrlParams({ digger, ready }: UseUrlParamsProps) {
  useEffect(() => {
    if (!ready) return;
    const [adCode, keyword, gclid, gbraid, wbraid, fbclid] = getUrlSearchParams(
      {
        keys: ['dig', 'kwd', 'gclid', 'gbraid', 'wbraid', 'fbclid']
      }
    );
    if (adCode) digger?.updateAdCode(adCode);
    if (keyword) digger?.updateKeyword(keyword);

    // Google
    if (gclid) digger?.updateGoogleClickId({ type: 'gclid', clickId: gclid });
    if (gbraid)
      digger?.updateGoogleClickId({ type: 'gbraid', clickId: gbraid });
    if (wbraid)
      digger?.updateGoogleClickId({ type: 'wbraid', clickId: wbraid });

    // Facebook
    if (fbclid)
      digger?.updateFacebookClickId({ type: 'fbclid', clickId: fbclid });
  }, [, digger, ready]);
}
