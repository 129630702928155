export interface DigtectiveOptions {
  sessionName: string;
  token: string;
  baseURL: string;
}

export enum DigtectiveLeadStatus {
  InProgress = 0,
  Won = 1,
  Lost = 2
}

export interface DigtectiveRequestInput {
  method?: 'POST';
  body: string;
}

export interface DigtectiveSubmitPayload {
  sessionId: string;
  path: string;
  browser: string;
  referrer?: string;
  adCode?: string;
  income?: number;
  status?: DigtectiveLeadStatus;
  originalReferrer?: string;
  customFields?: { [key: string]: string };
  googleClickId?: {
    clickId: string;
    type: 'gbraid' | 'wbraid' | 'gclid';
  };
  facebookClickId?: {
    clickId: string;
    type: 'fbclid';
  };
  keyword?: string;
}

export interface DigtectiveFormSubmissionResponse {
  status?: {
    code?: number;
    message?: string;
    error?: string;
  };
  data?: {
    formSubmissionId?: string;
  };
}
