import { useEffect } from 'react';
import { CommonHookProps } from '../types';

interface UseSessionProps extends CommonHookProps {}

export function useSession({ digger }: UseSessionProps, callback: () => void) {
  useEffect(() => {
    if (!digger) return;
    const createSession = async () => {
      const session = await digger?.createSession();
      if (session && typeof callback === 'function') callback();
    };
    createSession();
  }, [digger]);
}
