import { Digtective } from '..';
import { SubmitInput } from '../context/types';
import { DigtectiveLeadStatus, DigtectiveSubmitPayload } from '../types';

export function getPayload(facade: Digtective, input: SubmitInput) {
  const session = facade.getSession();
  if (!session) throw new Error('no session found for Digtective');
  const customFields = facade.getCustomFields(input);
  const {
    sessionId,
    originalReferrer,
    keyword,
    income,
    adCode,
    googleClickId,
    facebookClickId
  } = session ?? {};
  const body: DigtectiveSubmitPayload = {
    sessionId,
    path: window.location.href,
    browser: navigator.userAgent,
    referrer: document.referrer,
    originalReferrer:
      originalReferrer !== '' ? originalReferrer : window.location.href,
    status: DigtectiveLeadStatus.InProgress,
    income,
    keyword,
    adCode,
    googleClickId,
    facebookClickId,
    customFields
  };
  return body;
}
