import type { Digtective } from '..';

export async function createSession(facade: Digtective) {
  const { storage, options } = facade ?? {};
  const { sessionName } = options ?? {};
  const session = facade.getSession();
  const verifiedSession = facade.removeExpiredSession(session);
  if (verifiedSession) return true;
  const { v1: uuid } = await import('uuid');
  const expires = facade.getSessionExpiry();
  storage?.setItem(
    sessionName,
    JSON.stringify({
      sessionId: uuid(),
      expires,
      originalReferrer: document.referrer ?? window.location.href
    })
  );
  return true;
}
