export function convertStringToMS(timeStr: '30d' | '5h' | '15m' | '10s') {
  const units: { [key: string]: number } = {
    d: 24 * 60 * 60 * 1000,
    h: 60 * 60 * 1000,
    m: 60 * 1000,
    s: 1000
  };

  const match = timeStr.match(/^(\d+)([dhms])$/);
  if (!match)
    throw new Error("Invalid format. use '30d', '5h', '15m' or '10s'.");
  const value = parseInt(match[1], 10);
  const unit = match[2];
  return value * units[unit];
}
