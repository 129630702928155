import { Digtective } from '..';
import { SubmitCallback, SubmitInput } from '../context/types';
import { DigtectiveFormSubmissionResponse } from '../types';

export async function submitWithDigger(
  facade: Digtective,
  input: SubmitInput,
  callback: SubmitCallback
) {
  if (typeof callback !== 'function') throw new Error('no callback defined');
  try {
    const payload = facade.getPayload(input);
    const isReadyForSubmission = facade.validatePayload(payload);
    if (!isReadyForSubmission) return callback({ diggerId: undefined });
    const response = await facade.request<DigtectiveFormSubmissionResponse>(
      `/form-submission`,
      {
        body: JSON.stringify(payload)
      }
    );
    const { formSubmissionId } = response?.data ?? {};
    if (!formSubmissionId) return callback({ diggerId: undefined });
    facade.updateSession({ formSubmissionId });
    return callback({ diggerId: formSubmissionId });
  } catch (err) {
    console.error(err);
    return callback({ diggerId: undefined });
  }
}
